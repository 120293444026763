<template>
  <v-form v-model="valid" ref="form">
    <v-layout row wrap justify-center>
      <v-flex lg5 sm12 xs12>
        <div class="d-flex">
          <v-text-field class="mr-2" label="Fornecedor" v-model="pedido.nomeFornecedor" :readonly="true"></v-text-field>
        </div>
        <div class="d-flex">
          <v-text-field class="mr-2" label="Frete" v-model="pedido.tipoFrete" :readonly="true"></v-text-field>
        </div>
      </v-flex>
      <v-flex lg7 sm12 xs12>
        <div class="d-flex">
          <v-text-field
            class="mr-2"
            label="Data do pedido"
            v-model="pedido.dataPedidoString"
            :readonly="true"
          ></v-text-field>
          <v-text-field
            class="mr-2"
            label="Previsão de entrega"
            v-model="pedido.dataPrevistaEntregaString"
            :readonly="true"
          ></v-text-field>
          <v-text-field
            class="mr-2"
            label="Quantidade total"
            v-model="pedido.quantidade"
            :readonly="true"
          ></v-text-field>
        </div>
        <div class="d-flex">
          <v-text-field class="mr-2" label="Valor do frete" v-model="pedido.valorFrete" :readonly="true"></v-text-field>
          <v-text-field class="mr-2" label="Valor do pedido" v-model="pedido.valorPedido" :readonly="true">
          </v-text-field>
        </div>
      </v-flex>
      <v-flex lg12 sm12 xs12>
        <div class="d-flex">
          <v-data-table :headers="headersProduto" :items="produtos" hide-actions class="elevation-0">
            <template v-slot:items="props">
              <td style="display:none;">{{ props.item.id }}</td>
              <td>{{ props.item.referencia }}</td>
              <td>{{ props.item.nomeProduto }}</td>
              <td>{{ props.item.quantidade }}</td>
              <td>{{ props.item.precoUnitario }}</td>
              <td v-currency="{ currency: 'BRL', locale: 'pt-br' }">{{ props.item.valorTotal }}</td>
              <td v-if="props.item.entregue">{{ props.item.dataEntregaString }}</td>
              <td v-else>Pendente</td>
              <td>{{ props.item.grade }}</td>
              <td></td>
            </template>
          </v-data-table>
        </div>
        <div class="d-flex">
          <v-data-table :headers="headersPagamento" :items="pagamentos" hide-actions class="elevation-0">
            <template v-slot:items="props">
              <td style="display:none;">{{ props.item.id }}</td>
              <td>{{ props.item.valor.toLocaleString() }}</td>
              <td>{{ props.item.formaPagamento }}</td>
              <td>{{ props.item.dataPagamentoString }}</td>
            </template>
          </v-data-table>
        </div>
      </v-flex>
    </v-layout>
    <div class="form-btn">
      <v-btn @click="voltar" outline color="primary">Voltar</v-btn>
    </div>
  </v-form>
</template>
<script>
import { ServicoPedido } from "../../servicos/servicoPedido"
const servicoPedido = new ServicoPedido()
export default {
  data() {
    return {
      pedido: "",
      produtos: [],
      pagamentos: [],
      headersProduto: [
        { text: "Referência", align: "left", sortable: false, value: "referencia" },
        { text: "Nome do produto", align: "left", sortable: false, value: "nomeProduto" },
        { text: "Quantidade", align: "left", sortable: false, value: "quantidade" },
        { text: "Preço un.", align: "left", sortable: false, value: "precoUnitario" },
        { text: "Total", align: "left", value: "valorTotal", sortable: false },
        { text: "Data da entrega", align: "left", value: "dataEntregaString", sortable: false },
        { text: "Grade", align: "left", sortable: false }
      ],
      headersPagamento: [
        { text: "Valor", align: "left", sortable: false, value: "ValorPago" },
        { text: "Forma de pagamento", align: "left", sortable: false, value: "FormaPagamento" },
        { text: "Data", align: "left", sortable: false, value: "DataPagamento" }
      ]
    }
  },
  created() {
    if (this.$route && this.$route.params) {
      this.id = this.$route.params.id
    }
    if (this.id) {
      servicoPedido.buscarPorId(this.id).then(res => {
        this.pedido = res.data
        this.produtos = res.data.produtos
        this.pagamentos = res.data.pagamentos
        this.pedido.valorFrete = this.pedido.valorFrete.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL"
        })
        this.pedido.valorPedido = this.pedido.valorPedido.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL"
        })
      })
    }
  },
  methods: {
    voltar() {
      this.$router.push({ name: "Pedidos" })
    }
  }
}
</script>
<style scoped>
a.nounderline:link {
  text-decoration: none;
}
</style>
